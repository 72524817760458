import _ from 'lodash';
import axios from 'axios';
import Cookies from 'js-cookie';
import urljoin from 'url-join';
import store from '@/store';
import validationConfig from '@/config/validation.json';
import fieldsMixin from '@/mixins/mixin.fields';
import requestErrorHandlingCommonMixin from '@/mixins/mixin.requestErrorHandlingCommon';
import sentryCaptureRequestError from '@/utils/sentryCaptureRequestError';
import { sentryClient } from '@/utils/sentryClient';
import { prepareDataForBackendPost, hash } from '@/services/dataAdapter';
import { postRedirect } from '@/utils/postRedirect';
import { eT } from '../utils/eventTracking';
import { getTrackingCookies } from '@/utils/cookiesTracking';
import { sessionStorageEnabled } from '@/utils/checkSessionStorage';

export default {
    mixins: [fieldsMixin, requestErrorHandlingCommonMixin],
    methods: {
        validate(cb) {
            return this.$validator.validate().then((result) => {
                if (this.$validator.errors.items.length > 0) {
                    const invalidFieldNames = _.map(
                        this.$validator.errors.items,
                        errorItem => errorItem.field,
                    );
                    this.focusAndScrollToFirstInvalidField(invalidFieldNames);
                }
                if (!result) {
                    return;
                }
                cb();
            });
        },
        async validateZipcode() {
            const { zipcode } = this.$store.state.collectedData.customer;
            this.$store.commit('ui/setShowErrorInfoBox', false);

            const url = urljoin(
                process.env.VUE_APP_BACKEND_API_URL_ROOT,
                process.env.VUE_APP_BACKEND_API_URL_ZIPCODE_ENDPOINT,
                zipcode,
                '/',
            );

            return axios
                .get(url)
                .then((resp) => {
                    if (_.has(resp, 'data.operated_by_thermondo')) {
                        this.$store.dispatch(
                            'settings/isOperatedByThermondo/setIsOperatedByThermondo',
                            resp.data.operated_by_thermondo,
                        );
                    }

                    this.$router.push({
                        path: this.getNextStepPath(),
                    });
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        this.$validator.errors.add({
                            field: 'zipcode',
                            msg: validationConfig.custom.zipcode.backend_invalid,
                        });
                        eT({
                            event: 'zipcode_not_found',
                            zipcode_value: zipcode,
                        });
                    } else {
                        this.handleErrorCommon(error, {
                            showModal: false,
                            track: false,
                        });

                        sentryCaptureRequestError(
                            new Error(`[Zipcode] ${error}`),
                            error.response,
                        );
                    }
                });
        },
        async postToBackend() {
            this.$store.commit('ui/setShowErrorInfoBox', false);
            if (this.$store.state.ui.preventSubmit) {
                return undefined;
            }

            store.dispatch('ui/setPreventSubmit', true);

            const timeBeforeRedirect = 800;

            const data = prepareDataForBackendPost(
                this.$store.state,
                getTrackingCookies(),
            );

            const url = urljoin(
                process.env.VUE_APP_LEAD_SERVICE_API_URL_ROOT,
                process.env.VUE_APP_LEAD_SERVICE_API_URL_LEAD_ENDPOINT,
            );

            // We send first the email hash to the event tracking
            hash(data?.customer?.email ?? '')
                .then((hashEmail) => {
                    eT({
                        event: 'lf_request_submitted',
                        email_hashed: hashEmail,
                    });
                })
                .catch((error) => {
                    sentryClient.captureException(error);
                });

            return axios
                .post(url, data, {
                    withCredentials: true,
                    headers: {
                        'x-thermondo-authorization': `Token ${this.$store.state.settings.token.value}`,
                    },
                })
                .then((resp) => {
                    hash(data?.customer?.email ?? '')
                        .then((hashEmail) => {
                            eT({
                                event: 'lf_conversion',
                                user_id: resp.data.id || 'none',
                                email_hashed: hashEmail,
                            });
                        })
                        .catch((error) => {
                            sentryClient.captureException(error);
                        });

                    /**
                     * To prevent the user from submitting multiple times
                     * while waiting for the redirect
                     * to occur.
                     */
                    store.dispatch('ui/setRedirecting', true);

                    /**
                     * When a user submits a lead, they start with a fresh A/B test session
                     */
                    if (sessionStorageEnabled()) {
                        sessionStorage.removeItem('ab_test_session');
                    }

                    /**
                     * Redirect to pre-fill YouCanBookMe's appointment form.
                     *
                     * Our only way to let time for the event request to not be interrupted
                     * because of our redirect.
                     *
                     * The "sendBeacon" part will be done by marketing in Google Tag Manager.
                     *
                     * See this for more information:
                     * https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits#knowing_when_the_hit_has_been_sent
                     */
                    setTimeout(() => {
                        /**
                         * A few more information is needed by the confirmation page, in order to
                         * pre-fill the YouCanBook.Me calendar.
                         */
                        const CSRFToken = Cookies.get('csrftoken');

                        const params = {
                            csrfmiddlewaretoken: CSRFToken,
                            salutation: _.get(resp, 'data.customer.salutation'),
                            first_name: _.get(resp, 'data.customer.first_name'),
                            last_name: _.get(resp, 'data.customer.last_name'),
                            email: _.get(resp, 'data.customer.email'),
                            phone: _.get(resp, 'data.customer.phone'),
                        };

                        const redirectToUrl = new URL(
                            this.$store.state.settings.confirmationPageUrl.value,
                        );
                        redirectToUrl.searchParams.set('lf_submitted', 'true');
                        postRedirect(redirectToUrl.toString(), params);
                    }, timeBeforeRedirect);
                })
                .catch((error) => {
                    store.dispatch('ui/setPreventSubmit', false);

                    const invalidFieldNames = Object.keys(
                        _.get(error, 'response.data.customer[0]', []),
                    );
                    _.forEach(invalidFieldNames, (invalidFieldName) => {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                validationConfig.custom,
                                invalidFieldName,
                            )
                        ) {
                            this.$validator.errors.add({
                                field: invalidFieldName,
                                msg:
                                    validationConfig.custom[invalidFieldName]
                                        .backend_invalid,
                            });
                        }
                    });
                    if (invalidFieldNames.length > 0) {
                        this.focusAndScrollToFirstInvalidField(
                            invalidFieldNames,
                        );
                    }
                    sentryCaptureRequestError(
                        new Error(`[Post Backend] ${error}`),
                        error.response,
                    );
                    this.handleErrorCommon(error, {
                        showModal: false,
                        track: true,
                    });
                });
        },
        async getStatistics() {
            try {
                const statisticsURL = urljoin(
                    process.env.VUE_APP_BACKEND_API_URL_ROOT,
                    process.env.VUE_APP_BACKEND_API_URL_STATISTICS_ENDPOINT,
                );
                const resp = await axios.get(statisticsURL);
                if (_.has(resp, 'data')) {
                    return resp.data;
                }
                return false;
            } catch (error) {
                this.handleErrorCommon(error, {
                    showModal: false,
                    track: false,
                });

                sentryCaptureRequestError(
                    new Error(`[Statistics] ${error}`),
                    error.response,
                );
            }
            return false;
        },
        async getTerms() {
            const termsURL = urljoin(
                process.env.VUE_APP_HOMEPAGE_API_URL_ROOT,
                process.env.VUE_APP_HOMEPAGE_API_URL_PAGES_ENDPOINT,
                '188/?fields=body', // ID 188 is the Terms & Conditions page
            );

            try {
                const resp = await axios({
                    method: 'get',
                    url: termsURL,
                    responseType: 'json',
                });
                if (_.has(resp, 'data')) {
                    return resp.data;
                }
                return false;
            } catch (error) {
                this.handleErrorCommon(error, {
                    showModal: false,
                    track: false,
                });

                sentryCaptureRequestError(
                    new Error(`[Terms] ${error}`),
                    error.response,
                );
            }
            return false;
        },
    },
};
